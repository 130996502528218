import React from 'react';

import { Login } from 'src/components/login';
import { ErrorParameters, LoginMode, SignInParameters } from 'src/types/Login';
import { FormContainer } from 'src/components/formContainer';
import { Alternative } from 'src/components/alternative';

import * as S from './Authentication.styles';
import { ENV } from '../../constants';
import { useSelector } from 'react-redux';
import { SeddiLoginState } from '../../store/store';
import { FlagsModule } from '@seddi/ui-feature-flags';
import { MicrosoftLoginButton } from 'react-social-login-buttons';
import GoogleButton from 'react-google-button';

export const LoginPage: React.FC<{
  signInFunctions: SignInParameters;
  updateError: (error: { code?: string; message?: string; retry?: any } | null) => void;
  error: ErrorParameters;
  mode: LoginMode;
}> = ({ signInFunctions, updateError, error, mode }) => {
  let text = 'Create an account or log in to get started with SEDDI';
  let title: string;

  const showMicrosoftButton = FlagsModule.isEnabled("microsoft_sign_in");

  const { name } = useSelector((state: SeddiLoginState) => state.app);

  // TODO: add Textura at some point
  switch (ENV) {
    case 'vto-stag2':
    case 'vto-prod':
      text = text.concat(' Fitroom');
      title = 'Start your virtual try-on';
      break;
    default:
      // decorator login is handled in the same way as author, textura (same google project)
      if (name === 'decorator') {
        text = text.concat(' Decorator');
        title = 'Start making mockups in 3D';
      } else {
        text = text.concat(' Author');
        title = 'Start designing digitally';
      }
      break;
  }

  return (
    <FormContainer title={title} text={text}>
      <S.FieldsWrapper>
        <GoogleButton type='light' onClick={() => signInFunctions.onSignInWithGoogle()} />
        {showMicrosoftButton && (
        <MicrosoftLoginButton onClick={() => signInFunctions.onSignInWithMicrosoft()} />
        )}
        <Alternative />
        <Login
          error={error}
          updateError={updateError}
          signInFunctions={signInFunctions}
          mode={mode}
        />
      </S.FieldsWrapper>
    </FormContainer>
  );
};